import { useAuth } from 'context/authProvider'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'

import 'twin.macro'
/** @jsxImportSource @emotion/react */

const Footer = () => {
  const { t } = useTranslation('common')
  const { language } = i18n
  const { user } = useAuth()
  return (
    <footer tw="bg-gray-900 border-t border-gray-800 min-h-14">
      <div tw="px-6 lg:pl-[90px] m-auto flex flex-col xs:flex-row gap-2 xs:gap-0 py-4 justify-between items-center text-gray-100 text-2xs">
        <div tw="grid grid-flow-col gap-4">
          <a
            target="_blank"
            href={`https://refspace.com/r/${language}/terms-of-use`}
            rel="noreferrer"
          >
            {t('footer.rules')}
          </a>
          <a
            target="_blank"
            href={`https://refspace.com/r/${language}/privacy-policy`}
            rel="noreferrer"
          >
            {t('footer.privacyPolicy')}
          </a>
          {user?.userType === 'creator' && (
            <div>
              {' '}
              <a
                target="_blank"
                href={`https://refspace.com/${language}/r/creator-terms-and-conditions`}
                rel="noreferrer"
              >
                {t('footer.creator-terms-and-conditions')}
              </a>
            </div>
          )}
          {user?.userType === 'creator' && (
            <div>
              {' '}
              <a
                target="_blank"
                href={`https://refspace.com/${language}/r/creator-commission-rates`}
                rel="noreferrer"
              >
                {t('footer.creator-commission-rates')}
              </a>
            </div>
          )}
          {user?.userType === 'company' && (
            <div>
              {' '}
              <a
                target="_blank"
                href={`https://refspace.com/${language}/r/seller-commission-rates`}
                rel="noreferrer"
              >
                {t('footer.seller-commission-rates')}
              </a>
            </div>
          )}
        </div>
        <p tw="text-2xs">© {new Date().getFullYear()} REFSPACE LTD</p>
      </div>
    </footer>
  )
}

export { Footer }
